import * as React from "react"
import {
  BooleanField,
  CreateButton,
  DatagridConfigurable,
  DateField,
  DateInput,
  ExportButton,
  List,
  NullableBooleanInput,
  NumberField,
  SearchInput,
  SelectColumnsButton,
  TopToolbar,
  TextField,
  EmailField,
  useListContext,
  DeleteButton,
  useRecordContext,
  EditButton,
  ReferenceField,
  DeleteWithConfirmButton,
  ReferenceArrayField
} from "react-admin"
import { useMediaQuery, Button, Box, Typography } from "@mui/material"
import ContentFilter from '@mui/icons-material/FilterList';

import ProductFilterForm from "./ProductFilterForm"

const ProductFilterButton = () => {
  const { showFilter } = useListContext()
  return (
    <Button
      size="small"
      color="primary"
      onClick={() => showFilter("main")}
      startIcon={<ContentFilter />}
      sx={{
        height: '27.5px', // 調整按鈕高度
        padding: '4px 5px', // 調整內邊距
        fontSize: '13px', // 調整字型大小，這樣可以與 CreateButton 大小對齊
      }}
    >
      篩選
    </Button>
  )
}

const visitorFilters = [
  <SearchInput source="q" alwaysOn />,
  // <DateInput source="last_seen_gte" />,
  // <NullableBooleanInput source="has_ordered" />,
  // <NullableBooleanInput source="has_newsletter" defaultValue />,
  // <SegmentInput source="groups" />
]

const ProductListActions = () => (
  <Box width="100%">
    <TopToolbar>
      <ProductFilterButton />
      <CreateButton />
      {/* <SelectColumnsButton /> */}
      {/* <ExportButton /> */}
    </TopToolbar>
    <ProductFilterForm />
  </Box>
)

const ProductTitle = () => {
  return <span>{'商品'}</span>;
};

const ProductList = () => {
  const isXsmall = useMediaQuery(theme => theme.breakpoints.down("sm"))
  const isSmall = useMediaQuery(theme => theme.breakpoints.down("md"))
  return (
    <List
      title={<ProductTitle/>}
      filters={isSmall ? visitorFilters : undefined}
      sort={{ field: "code", order: "ASC" }}
      perPage={25}
      actions={<ProductListActions />}
    >
      {isXsmall ? (
        // <MobileGrid />
        <div></div>
      ) : (
        <DatagridConfigurable
          rowClick="edit"
          sx={{
            "& .column-groups": {
              md: { display: "none" },
              lg: { display: "table-cell" }
            }
          }}
          omit={["id"]}
        >
          <TextField
            source="id"
            label="ID"
          />
          <TextField
            source="code"
            label="編號"
          />
          <TextField
            source="name"
            label="名稱"
          />
          <ReferenceArrayField 
            reference="product-categories" 
            source="categories" 
            label="系列" 
            />
          <NumberField 
            source="price" 
            label="價錢"
            textAlign="right"
            options={{
              style: 'currency',
              currency: 'TWD',
              minimumFractionDigits: 2,
            }}
          />
          <BooleanField
            source="status"
            label="上架狀態"
          />
          <DateField 
            source="createdAt" 
            label="建立日期"  
            showTime
          />
          <DeleteWithConfirmButton
            confirmTitle="確認刪除"
            confirmContent="您確定要刪除此類別嗎？"
          />
        </DatagridConfigurable>
      )}
    </List>
  )
}

export default ProductList