import * as React from "react"
import {
  Create,
  SimpleForm,
  TextInput,
  useTranslate,
  email,
  SelectInput,
  DateTimeInput,
  BooleanInput,
  ReferenceInput
} from "react-admin"
import { Box, Typography, Grid } from "@mui/material"

const CategoryTitle = () => {
  return <span>{'商品系列'}</span>;
};

export const validateForm = values => {
  const errors = {}
  if (!values.name) {
    errors.name = "ra.validation.required"
  }
  return errors
}

const CategoryCreate = () => {
  const translate = useTranslate()
  return (
    <Create title={<CategoryTitle/>}>
      <SimpleForm
        // Here for the GQL provider
        defaultValues={{
          name: "",
          code: "",
          status: 1,
          description: "",
          type: "",
        }}
        validate={validateForm}
      >
        <Grid container width={{ xs: "100%", xl: 800 }} spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextInput autoFocus source="name" label="名稱" isRequired />
            </Grid>
            <Grid item xs={12} sm={4} 
              sx={{
                display: "flex",
                justifyContent: "right"
              }}
            >
              <BooleanInput label="啟用狀態" source="status" />
            </Grid>
            <Grid item xs={12} sm={8}>
              <ReferenceInput source="parentId" 
                reference="categories" 
                filter={{ type: 'product', level: 'top' }}
                sort={{ field: 'sorting', order: 'ASC' }}
              >
                <SelectInput optionText="name" label="父類別" emptyText="無" />
              </ReferenceInput>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextInput source="sorting" label="排序編號" isRequired />
            </Grid>
            {/* <Grid item xs={12} sm={8}>
              <TextInput source="code" label="編號" />
            </Grid> */}
            <Grid item xs={8}>
              <TextInput source="description"
                label="備註" 
                multiline
                rows={4} // 設定多行的行數
              />
            </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  )
}

const SectionTitle = ({ label }) => {
  const translate = useTranslate()

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  )
}

const Separator = () => <Box pt="1em" />

export default CategoryCreate