import * as React from "react"
import {
  Create,
  SimpleForm,
  TextInput,
  useTranslate,
  email,
  SelectInput,
  DateTimeInput,
} from "react-admin"
import { Box, Typography, Grid } from "@mui/material"

const ContactTitle = () => {
  return <span>{'聯絡表單'}</span>;
};

export const validateForm = values => {
  const errors = {}
  if (!values.name) {
    errors.name = "ra.validation.required"
  }
  if (!values.email) {
    errors.email = "ra.validation.required"
  } else {
    const error = email()(values.email)
    if (error) {
      errors.email = error
    }
  }
  if (!values.requestType) {
    errors.requestType = "ra.validation.required"
  }
  if (!values.status) {
    errors.status = "ra.validation.required"
  }
  if (values.requestType !== "subscribe" && !values.message) {
    errors.message = "ra.validation.required"
  }
  if (values.status === "resolved" && !values.resolvedAt) {
    errors.resolvedAt = "ra.validation.required"
  }
  return errors
}

const ContractCreate = () => {
  const translate = useTranslate()
  return (
    <Create title={<ContactTitle/>}>
      <SimpleForm
        // Here for the GQL provider
        defaultValues={{
          name: "",
          email: "",
          phone: "",
          requestType: "subscribe",
          status: "pending",
          message: "",
          response: "",
          resolvedAt: "",
        }}
        validate={validateForm}
        sx={{ maxWidth: "50em" }}
      >
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <SelectInput source="requestType" 
                label="需求類型" 
                isRequired
                choices={[
                  { id: 'subscribe', name: '訂閱我們' },
                  { id: 'join', name: '加入我們' },
                  { id: 'contact', name: '聯絡我們' },
                  { id: 'become_partner', name: '成為廠商' }
                ]} 
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SelectInput source="status" 
                label="處理狀態" 
                isRequired
                choices={[
                  { id: 'pending', name: '待處理' },
                  { id: 'in_progress', name: '聯繫中' },
                  { id: 'resolved', name: '已完成' },
                  { id: 'closed', name: '結束' }
                ]} 
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DateTimeInput source="resolvedAt"
                label="完成時間"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextInput autoFocus source="name" label="客戶名稱"  isRequired />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextInput source="email" label="Email" isRequired />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextInput source="phone" label="聯絡電話" />
            </Grid>
            <Grid item xs={12}>
              <TextInput source="message"
                label="詳細內容" 
                multiline
                rows={4} // 設定多行的行數
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput source="response"
                label="紀錄備註" 
                multiline
                rows={4} // 設定多行的行數
              />
            </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  )
}

const SectionTitle = ({ label }) => {
  const translate = useTranslate()

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  )
}

const Separator = () => <Box pt="1em" />

export default ContractCreate