import CategoryIcon from '@mui/icons-material/Bookmark';
import CategoryList from './CategoryList';
import CategoryCreate from './CategoryCreate';
import CategoryEdit from './CategoryEdit';

const resource = {
  list: CategoryList,
  create: CategoryCreate,
  edit: CategoryEdit,
  icon: CategoryIcon,
}
  
export default resource
