import * as React from "react"
import { 
  Create, 
  TabbedForm, 
  TextInput, 
  required, 
  ImageInput, 
  ImageField, 
  ArrayInput, 
  SimpleFormIterator, 
  FileInput, 
  FileField, 
  ReferenceInput, 
  SelectArrayInput, 
  NumberInput,
  BooleanInput
} from "react-admin"
import {InputAdornment, Grid, Box, Typography } from "@mui/material"

const ProductTitle = () => {
  return <span>{'商品'}</span>;
};

const validateForm = values => {
  const errors = {}
  if (!values.name) {
    errors.name = "ra.validation.required"
  }
  if (values.categories.length === 0) {
    errors.categories = "ra.validation.required"
  }
  if (!values.coverImage.rawFile) {
    errors.coverImage = "ra.validation.required"
  }
  if (!values.templateImage.rawFile) {
    errors.templateImage = "ra.validation.required"
  }
  if (!values.minQuantity) {
    errors.minQuantity = "ra.validation.required"
  }
  if (!values.maxQuantity) {
    errors.maxQuantity = "ra.validation.required"
  }
  if (!values.incrementStep) {
    errors.incrementStep = "ra.validation.required"
  }
  return errors
}

const ProductCreate = () => (
  <Create title={<ProductTitle/>}>
    <TabbedForm 
      defaultValues={{
        code: "",
        name: "",
        categories: [],
        price: 0,
        stock: 0,
        minOrderQuantity: 1,
        maxOrderQuantity: "",
        samplingTime: "",
        samplingFee: "",
        minQuantity: 1,
        maxQuantity: 10,
        incrementStep: 1,
        description: "",
        specification: "",
        status: 1,
        coverImage: [],
        templateImage: [],
        colors: [
          {
            name: "",
            code: "",
            navImage: [],
            image: [],
          }
        ],
        specifications: [
          {
            name: "",
          }
        ],
        quantityDiscounts: [
          {
            minQuantity: "",
            maxQuantity: "",
            discountPercentage: "",
            leadTimeDays: "",
          }
        ]
      }}
      validate={validateForm}
    >
      <TabbedForm.Tab
        label="resources.products.tabs.image"
        sx={{ maxWidth: "50em" }}
      >
        <ImageInput 
          source="coverImage" 
          label="封面圖片" 
          accept={{ 'image/*': ['.png', '.jpg'] }} 
          sx={{
            "& .previews": {
              backgroundColor: "#f0f0f0", // 外層容器灰底
              padding: "10px", // 圖片周圍留白
              borderRadius: "4px", // 圓角效果
              border: "1px solid #ccc", // 灰色邊框
            },
          }}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageInput 
          source="templateImage" 
          label="模板圖片" 
          accept={{ 'image/*': ['.png', '.jpg'] }} 
          sx={{
            "& .previews": {
              backgroundColor: "#f0f0f0",
              padding: "10px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            },
            marginTop: "1rem",
          }}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageInput 
          source="otherImage" 
          label="其他圖片" 
          accept={{ 'image/*': ['.png', '.jpg'] }} 
          multiple 
          sx={{
            "& .previews": {
              backgroundColor: "#f0f0f0",
              padding: "10px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            },
            marginTop: "1rem",
          }}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </TabbedForm.Tab>
      <TabbedForm.Tab
        label="基本資料"
        path="basic"
        sx={{ maxWidth: "50em" }}
      >
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={8}>
            <TextInput source="code" label="編號(可選擇手動輸入編號或由系統自動生成)" />
          </Grid>
          <Grid item xs={12} sm={4} 
            sx={{
              display: "flex",
              justifyContent: "right"
            }}
          >
            <BooleanInput label="啟用狀態" source="status" />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextInput source="name" label="名稱" isRequired />
          </Grid>
          <Grid item xs={12} sm={12}>
            <ReferenceInput source="categories" 
              reference="product-categories" 
              sort={{ field: 'sorting', order: 'ASC' }}
            >
              <SelectArrayInput optionText="label" label="系列" isRequired />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} sm={6}>
            <NumberInput
              source="price"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <NumberInput source="stock" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <NumberInput source="minOrderQuantity" label="最低下訂量" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <NumberInput source="maxOrderQuantity" label="最高下訂量" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <NumberInput source="samplingTime" label="打樣所需時間(天)" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <NumberInput source="samplingFee" label="打樣所需費用" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <NumberInput source="minQuantity" label="數量選項區間最小值" isRequired />
          </Grid>
          <Grid item xs={12} sm={4}>
            <NumberInput source="maxQuantity" label="數量選項區間最大值" isRequired />
          </Grid>
          <Grid item xs={12} sm={4}>
            <NumberInput source="incrementStep" label="數量選項區間值" isRequired />
          </Grid>
        </Grid>
      </TabbedForm.Tab>
      <TabbedForm.Tab
        label="顏色選項"
        path="color-options"
        sx={{ maxWidth: "50em" }}
      >
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={12}>
            <ArrayInput source="colors" label="顏色選項">
              <SimpleFormIterator getItemLabel={index => `#${index + 1}`}>
                <TextInput source="name" label="名稱" />
                <TextInput source="code" label="代碼" />
                <ImageInput 
                  source="navImage" 
                  label="圖片" 
                  accept={{ 'image/*': ['.png', '.jpg'] }} 
                  sx={{
                    "& .previews": {
                      backgroundColor: "#f0f0f0",
                      padding: "10px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                    },
                  }}
                >
                  <ImageField source="src" title="title" />
                </ImageInput>
                <ImageInput 
                  source="image" 
                  label="對應的商品圖" 
                  accept={{ 'image/*': ['.png', '.jpg'] }} 
                  sx={{
                    "& .previews": {
                      backgroundColor: "#f0f0f0",
                      padding: "10px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                    },
                    marginTop: "1rem",
                  }}
                >
                  <ImageField source="src" title="title" />
                </ImageInput>
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>
        </Grid>
      </TabbedForm.Tab>
      <TabbedForm.Tab
        label="規格選項"
        path="specification-options"
        sx={{ maxWidth: "50em" }}
      >
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={12}>
            <ArrayInput source="specifications" label="規格選項">
              <SimpleFormIterator getItemLabel={index => `#${index + 1}`}> 
                <TextInput source="name" label="名稱" />
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>
        </Grid>
      </TabbedForm.Tab>
      <TabbedForm.Tab
        label="數量折扣規則"
        path="quantity-discounts"
        sx={{ maxWidth: "50em" }}
      >
        <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={12}>
          <ArrayInput source="quantityDiscounts" label="數量折扣規則">
            <SimpleFormIterator inline getItemLabel={index => `#${index + 1}`}> 
              <TextInput source="minQuantity" label="最小購買數量" />
              <TextInput source="maxQuantity" label="最大購買數量" />
              <TextInput source="discountPercentage" label="折扣百分比" />
              <TextInput source="leadTimeDays" label="交期天數" />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
        </Grid>
      </TabbedForm.Tab>
      <TabbedForm.Tab
        label="描述"
        path="description"
        sx={{ maxWidth: "50em" }}
      >
        <TextInput source="description"
          label="描述" 
          multiline
          rows={4} // 設定多行的行數
        />
        <TextInput source="specification"
          label="詳細規格" 
          multiline
          rows={8} // 設定多行的行數
        />
      </TabbedForm.Tab>
    </TabbedForm>
  </Create>
)

export default ProductCreate